import { Application, Controller } from 'stimulus'

export default class DateRangePickerController extends Controller {

  static targets = [ 'day', 'months', 'customSelector', 'beginsAtInput', 'endsAtInput', 'delete' ]
  static values = { startDate: {type: String, default: ''}, endDate: {type: String, default: ''} }
  startDateSelected = null
  endDateSelected = null
  searchForm = this.beginsAtInputTarget.closest('form')
  rangeSelected = []

  connect() {
    window.date_range_picker = this;
  }

  initializeDates() {
    this.resetDaySelection();
    if (this.startDateValue != '') { this.initializeStartDate(); }
    if (this.endDateValue != '' && this.endDateValue != this.startDateValue) { this.initializeEndDate(); }
  }

  initializeStartDate() {
    const selectedDay = this.dayTargets.find(day => day.dataset.date == this.momentDateString(this.startDateValue));
    selectedDay.classList.add('day-selected');
    this.startDateSelected = selectedDay;
    this.selectCustomSelector(this.startDateSelected.dataset.date, true);
    this.deleteTarget.classList.remove('d-none');
  }

  initializeEndDate() {
    const selectedDay = this.dayTargets.find(day => day.dataset.date == this.momentDateString(this.endDateValue));
    selectedDay.classList.add('day-selected');
    this.endDateSelected = selectedDay;
    this.selectDayRange();
    this.resetCustomSelector();
  }

  selectDay(e, customSelection) {
    setTimeout(() => {
      const selectedDay = customSelection ? customSelection : e.target.closest("[data-date-range-picker-target=day]");
      if (this.startDateSelected && this.endDateSelected) { this.resetDaySelection(); };
      selectedDay.classList.add('day-selected');
      if (!this.startDateSelected) {
        this.startDateSelected = selectedDay;
        this.selectCustomSelector(this.startDateSelected.dataset.date, true);
        this.deleteTarget.classList.remove('d-none');
      }else {
        this.endDateSelected = selectedDay;
        if (this.momentDate(this.startDateSelected) == this.momentDate(this.endDateSelected)) {
          this.selectSameDay();
          this.resetCustomSelector();
        } else if (this.momentDate(this.startDateSelected) > this.momentDate(this.endDateSelected) || customSelection) {
          this.selectUniqueDay();
          this.selectCustomSelector(this.startDateSelected.dataset.date, true);
        } else if (this.momentDate(this.startDateSelected) < this.momentDate(this.endDateSelected)) {
          this.selectDayRange();
          this.resetCustomSelector();
        }
      }
    }, 0);
}

  customSelectDay(e) {
    this.selectCustomSelector(e.params.date);
    this.monthsTarget.parentElement.scrollTo({ top: 0 });
  }

  resetCustomSelector() {
    this.customSelectorTargets.forEach(customSelector => {customSelector.classList.remove('chip-item-selected')});
  }

  selectCustomSelector(customSelectorDay, fromCalendar = false) {
    this.customSelectorTargets.forEach(customSelector => {customSelector.classList.remove('chip-item-selected')});
    const currentCustomSelector = this.customSelectorTargets.find(customSelector => customSelector.dataset.dateRangePickerDateParam == customSelectorDay);
    if(currentCustomSelector) { currentCustomSelector.classList.add('chip-item-selected') };
    if (!fromCalendar) {
      const customSelectionDay = this.dayTargets.find(day => customSelectorDay == day.dataset.date);
      this.selectDay(null, customSelectionDay);
    }
  }

  resetDaySelection() {
    this.resetDay(this.startDateSelected);
    this.resetDay(this.endDateSelected);
    this.rangeSelected.forEach(day => { day.classList.remove('day-range') });
    this.startDateSelected = null;
    this.endDateSelected = null;
    this.beginsAtInputTarget.value = null;
    this.endsAtInputTarget.value = null;
    this.customSelectorTargets.forEach(customSelector => {customSelector.classList.remove('chip-item-selected')});
    this.deleteTarget.classList.add('d-none');
  }

  selectSameDay() {
    this.resetDay(this.startDateSelected);
    this.startDateSelected = null;
    this.endDateSelected = null;
    this.deleteTarget.classList.add('d-none');
  }

  selectUniqueDay() {
    this.resetDay(this.startDateSelected);
    this.startDateSelected = this.endDateSelected;
    this.endDateSelected = null;
    this.deleteTarget.classList.remove('d-none');
  }

  selectDayRange() {
    this.rangeSelected = this.dayTargets.filter(day => {
      return this.momentDate(this.startDateSelected) < this.momentDate(day) && this.momentDate(day) < this.momentDate(this.endDateSelected);
    });
    this.rangeSelected.forEach(day => {
      this.resetDay(day);
      day.classList.add('day-range')
    });
    this.startDateSelected.classList.add('first-range-selected');
    this.endDateSelected.classList.add('last-range-selected');
    this.deleteTarget.classList.remove('d-none');
  }

  resetDay(day) {
    if (day) {
      day.classList.remove('day-selected');
      day.classList.remove('first-range-selected')
      day.classList.remove('last-range-selected')
    }
  }

  selectPastDay(e) {
    e.preventDefault();
  }

  momentDate(date, format = 'YYYY-MM-DD') {
    return gon.moment(date.dataset.date).locale(gon.locale).format(format)
  }

  momentDateString(date, format = 'YYYY-MM-DD') {
    return gon.moment(date, 'DD/MM/YYYY').locale(gon.locale).format(format)
  }

  backToSearchWithSuggestions() {
    window.modal_bsht_date_range_picker.desactivate();
    window.modal_bsht_search_suggestions.activate();
    window.modal_bsht_search_suggestions.fullScreen();
  }

  submitSearch() {
    setTimeout(() => {
      this.showLoader();
      this.setDates();
      this.submitForm();
    }, 0);
  }

  showLoader() {
    window.showLoadingElements();
  }

  submitForm() {
    if(this.searchForm.requestSubmit) {
      this.searchForm.requestSubmit();
    }else {
      this.searchForm.dispatchEvent(new CustomEvent('submit', {bubbles: true}))
    }
  }

  setDates() {
    if(this.startDateSelected) { this.setBeginsAt(); }
    if(this.endDateSelected) {
      this.setEndsAt();
    } else if(this.startDateSelected) {
     this.setSameDate();
    }
  }

  setBeginsAt() {
    this.beginsAtInputTarget.value = this.momentDate(this.startDateSelected, 'DD/MM/YYYY');
  }

  setEndsAt() {
    this.endsAtInputTarget.value = this.momentDate(this.endDateSelected, 'DD/MM/YYYY');
  }

  setSameDate() {
    this.endsAtInputTarget.value = this.momentDate(this.startDateSelected, 'DD/MM/YYYY');
  }

}

const application = Application.start()
application.register('date-range-picker', DateRangePickerController)
